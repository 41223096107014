.news-post {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    justify-content: space-between;

    @media only screen and (max-width: 992px) {
        flex-direction: column;
    }

    .post-text {
        width: 70%;

        .post-title {
            display: block;
            font-size: 26px;
            width: 100%;
            font-family: 'Evogria';
            border-bottom: 2px solid #fff;
            color: #fff;
        }

        .post-meta {
            display: block;
            width: 100%;
            font-size: 12px;
        }

        .post-content {

        }
    }

    .post-image {
        width: 225px;
        height: 150px;
        background-color: $club-red;
        border-radius: 10px;
        flex-shrink: 0;
        align-content: end;

        &.full {
            width: 100%;
            height: 10%;
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
}

#news-archive {
    .post-title {
        color: #000;
    }
}
