.event-users {
    display: flex;

    .event-user {
        border: 1px solid gray;
        border-radius: 5px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: gray;
        margin-bottom: 1rem;

        .event-user-name {
            margin-bottom: 0;
        }
    }
}

#upcoming-events {
    .event {
        background-position: center;
        width: 100%;
        height: 350px;
        display: flex;
        align-items: flex-end;

        .event-details {
            padding: 1rem;
            width: 100%;
            background-color: rgba(60, 60, 60, 0.8);
            color: #fff;
        }
    }
}

.events {
    h4 {
        font-size: 1.5rem;
        border-bottom: 3px solid $club-green;
        font-family: 'Evogria-Italic';
    }

    .calendar {
        width: 100%;

        .header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .month {
                font-family: 'Evogria';
                font-size: 1.25rem;
            }
        }

        .week {
            display: flex;
            width: 100%;
            justify-content: space-around;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            .day {
                width: calc(100% / 7);
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                border-radius: 3px;
                cursor: pointer;

                &.prev, &.next {
                    color: lightgray;
                }

                &.has-event {
                    text-decoration: underline;
                    text-decoration-color: $club-red;
                    text-decoration-thickness: 2px;
                }

                &.selected {
                    background-color: grey;
                    color: #fff;
                }

                &:hover {
                    background-color: lightgray;
                    color: #fff;
                }
            }
        }
    }

    .events-list {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .event {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-around;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .event-icon {
                color: $club-red;
                margin-right: .25rem;
            }

            .event-title {
                font-family: 'Evogria';
                margin: 0;
            }
        }
    }
}
