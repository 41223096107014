@font-face {
    font-family: 'Evogria';
    font-style: normal;
    src: url('/fonts/Evogria.otf');
}

@font-face {
    font-family: 'Evogria-Italic';
    font-style: normal;
    src: url('/fonts/Evogria-Italic.otf');
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Evogria", sans-serif;
}

a, a:hover {
    color: #000;
    text-decoration: none;
}
