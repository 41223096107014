.carousel {
    height: 450px;
    overflow: hidden;

    .carousel-inner {
        height: 100%;

        .carousel-item {
            height: 100%;

            .carousel-caption {
                background-color: rgba(65, 67, 61, 0.7);
                padding: .3rem;

                h5 {
                    width: 100%;
                    border-bottom: 3px solid $club-green;
                }

            }
        }
    }
}

#news-header {
    border-bottom: 3px solid #fff;
    font-family: 'Evogria-Italic';
}

#activity-header {
    font-family: 'Evogria-Italic';
    border-bottom: 3px solid #fff;
}

.strava-container {
    display: flex;
    justify-content: center;
}

#welcome-header {
    font-family: 'Evogria-Italic';
    border-bottom: 3px solid $club-green;
}
