.footer {
    font-size: 1rem;
    font-family: 'Evogria-Italic';
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .footer-links {
        display: flex;
        flex-direction: column;
        a {
            color: #fff;
        }
    }
}
