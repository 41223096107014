// Body
$body-bg: #ffffff;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;


// Colours
$club-red: #ef4c46;
$club-green: #22925c;
