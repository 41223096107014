#main-nav {
    background-color: #f8f9fa;
    text-align: center;
    border-bottom: 3px solid $club-green;

    #nav-logo {
        width: 100%;
        margin: auto;
        padding: 1.5rem;
        display: flex;
        justify-content: center;

        #nav-logo-text {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 1.7rem;
            font-family: 'Evogria-Italic';
            text-decoration: underline;
            text-decoration-color: $club-red;
            border-bottom: 3px solid $club-green;
        }
    }
}

.nav-link {
    font-family: 'Evogria';
    font-size: 1rem;
}

.nav-item {
    display: flex;
    align-items: center;
}
